import { useState } from "react";
import Api from "../Api";
import { IconThumbDown } from '../Components/IconThumbDown';
import { IconThumbUp } from '../Components/IconThumbUp';
import { toast } from 'react-toastify';


export default function MessageFeedback({messageId, api, originRating}: {messageId: string, api: Api, originRating: number}) {
  const [rating, setRating] = useState(originRating);
  const thumbUp = () => {
    api.thumbUp(messageId).catch(() => {
      toast.error("Error thumb up!");
    }).then(() => {
      setRating(1);
    })
  };

  const thumbDown = () => {
    api.thumbDown(messageId).catch(() => {
      toast.error("Error thumb down!");
    }).then(() => {
      setRating(-1);
    })
  };

  return (
    <>
      <button className="thumb" onClick={thumbUp}><IconThumbUp selected={rating === 1} size={24} /></button>
      <button className="thumb" onClick={thumbDown}><IconThumbDown selected={rating === -1} size={24} /></button>
    </>
  )
}
