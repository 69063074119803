import axios from 'axios';

export default class Api {
  baseUrl: string;
  session: string;

  constructor(baseUrl: string, session: string) {
    this.baseUrl = baseUrl;
    this.session = session;
  }

  getMessages() {
    return axios({
      method: "get",
      url: `${this.baseUrl}/v1/messages`,
      headers: {session: this.session}
    })
  }

  sendMessage(data: any) {
    return axios({
      method: "post",
      url: `${this.baseUrl}/v1/messages/converse`,
      headers: {session: this.session},
      data: data
    })
  }

  clearSession() {
    return axios({
      method: "delete",
      url: `${this.baseUrl}/v1/messages`,
      headers: {session: this.session},
    })
  }

  thumbUp(messageId: string) {
    return axios({
      method: "post",
      url: `${this.baseUrl}/v1/messages/${messageId}/feedback`,
      headers: {session: this.session},
      data: {
        data: {
          rating: 1
        }
      }
    })
  }

  thumbDown(messageId: string) {
    return axios({
      method: "post",
      url: `${this.baseUrl}/v1/messages/${messageId}/feedback`,
      headers: {session: this.session},
      data: {
        data: {
          rating: -1
        }
      }
    })
  }
}
