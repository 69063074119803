import React, {useEffect, useState, useMemo} from 'react';
import logo from './logo.webp';
import './App.css';
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  toggleMsgLoader,
  dropMessages,
  markAllAsRead,
  addLinkSnippet,
  renderCustomComponent,
  setQuickButtons
} from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from './Api';
import {uuid} from './utils';
import MessageFeedback from './Components/MessageFeedback';

function segmentToMDLink(segment: any) {
  return `[${segment.content}](${segment.url})`
}

function renderAssistantMessage(message: any, api: Api) {
  const segments = message.segments as any[];
  let content = segments.filter(s => s.type === 'text').map(s => s.content).join('\n');
  const links = segments.filter(s => s.type === 'url').map(segmentToMDLink).join('\n')

  if (links) {
    content +=  "\n\n" + links;
  }

  addResponseMessage(content);
  renderCustomComponent(MessageFeedback, {messageId: message.id, api: api, originRating: message.feedback_rating});
}

class Config {
  noBrokenLinks: boolean;
  noToxicity: boolean;
  noDuplication: boolean;
  useAcronyms: boolean;

  constructor(noBrokenLinks: boolean, noToxicity: boolean, noDuplication: boolean, useAcronyms: boolean) {
    this.noBrokenLinks = noBrokenLinks;
    this.noToxicity = noToxicity;
    this.noDuplication = noDuplication;
    this.useAcronyms = useAcronyms;
  }

  save() {
    const data = {
      noBrokenLinks: this.noBrokenLinks,
      noDuplication: this.noDuplication,
      noToxicity: this.noToxicity,
      useAcronyms: this.useAcronyms
    };
    localStorage.setItem("config", JSON.stringify(data));
  }
}

function App() {
  const [session] = useState(() => {
    let session_id = localStorage.getItem("session_id");
    if (!session_id) {
      session_id = uuid();
      localStorage.setItem("session_id", session_id);
    }
    return session_id || "";
  });

  const [config, setConfig] = useState(() => {
    let config = new Config(false, false, false, false);
    let config_data = localStorage.getItem("config");
    if (config_data) {
      let data = JSON.parse(config_data);
      if (data.noBrokenLinks) {
        config.noBrokenLinks = !!data.noBrokenLinks;
      }
      if (data.noDuplication) {
        config.noDuplication = !!data.noDuplication;
      }
      if (data.noToxicity) {
        config.noToxicity = !!data.noToxicity;
      }
      if (data.useAcronyms) {
        config.useAcronyms = !!data.useAcronyms;
      }
    }
    return config;
  });

  let api = useMemo(() => {
    return new Api(process.env.REACT_APP_API_URI || "", session);
  }, [session]);

  useEffect(() => {
    let buttons = [];

    buttons.push({
      label: "Filter duplicate: " + (config.noDuplication ? "On" : "Off"),
      value: "duplicate",
    });

    buttons.push({
      label: "Filter broken links: " + (config.noBrokenLinks ? "On" : "Off"),
      value: "broken_links",
    });

    buttons.push({
      label: "Filter toxicity: " + (config.noToxicity ? "On" : "Off"),
      value: "toxicity",
    });

    buttons.push({
      label: "Enrich acronyms: " + (config.useAcronyms ? "On" : "Off"),
      value: "acronyms",
    });

    setQuickButtons(buttons);
  }, [config]);

  useEffect(() => {
    dropMessages();
    api.getMessages().then((res: any) => {
      const messages = res.data.data;
      console.log(res)

      if (messages.length) {
        for (let message of messages.reverse()) {

          if (message.role === 'assistant') {
            renderAssistantMessage(message, api);
          }

          if (message.role === "user") {
            for (let segment of message.segments) {
              addUserMessage(segment.content);
            }
          }
        }
        markAllAsRead();
      } else {
        addResponseMessage('Welcome to this awesome chat!');
      }
    }).catch(() => {
      toast.error("Error loading history!");
    });
  }, [api]);

  const handleNewUserMessage = (newMessage: string) => {
    const data = {
      data: {
        "text": newMessage,
        "config": config
      }
    };

    toggleMsgLoader();
    api.sendMessage(data).then((res: any) => {
      let message = res.data.data[1];
      let segments = message.segments;
      renderAssistantMessage(message,  api);
    }).catch(() => {
      toast.error("Error calling LLM!");
    }).finally(() => {
      toggleMsgLoader();
    });
  };

  const clearSession = () => {
    api.clearSession().then(() => {
      dropMessages();
      toast.info("Chat cleared.");
    }).catch(() => {
      toast.error("Error deleting all messages!");
    });
  }

  const onQuickButtonClicked = (value: string) => {
    if (value === "duplicate") {
      let newConfig = new Config(config.noBrokenLinks, config.noToxicity, !config.noDuplication, config.useAcronyms);
      setConfig(newConfig);
      newConfig.save();
    } else if (value === "broken_links") {
      let newConfig = new Config(!config.noBrokenLinks, config.noToxicity, config.noDuplication, config.useAcronyms);
      setConfig(newConfig);
      newConfig.save();
    } else if (value === "toxicity") {
      let newConfig = new Config(config.noBrokenLinks, !config.noToxicity, config.noDuplication, config.useAcronyms);
      setConfig(newConfig);
      newConfig.save();
    } else if (value === "acronyms") {
      let newConfig = new Config(config.noBrokenLinks, config.noToxicity, config.noDuplication, !config.useAcronyms);
      setConfig(newConfig);
      newConfig.save();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
      </header>
      <Widget
        showCloseButton={true}
        handleNewUserMessage={handleNewUserMessage}
        autofocus={true}
        emojis={true}
        resizable={true}
        showTimeStamp={false}
        subtitle={""}
        handleQuickButtonClicked={onQuickButtonClicked}
        title={<div key={"123"}>Shelf chat <br /><button onClick={clearSession}>clear</button></div>}
      />
      <ToastContainer />
    </div>
  );
}

export default App;
